import React, {Component} from 'react';
import HowItWorks from './HowItWorks';

class HowItWorksContainer extends Component {
  render() {
    return (
      <HowItWorks {...this.props} />
    )
  }
}


export default HowItWorksContainer