import React from 'react';
import { css } from 'aphrodite';
import styles from './AppHero.styles';
import STYLES from '../../../styles/shared';
import Utils from '../../../library/utils';

const AppHero = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>

      <div className={css(styles.titleArea)}>
        <h1 className={css(styles.h1)}>Man and van made easy</h1>
        <p className={css(styles.description)}>We got vans. We got drivers. We got lifters.</p>
      </div>

      <div className={css(styles.imageArea)}>
        <img className={css(styles.appHandImage)} src={require('../../../assets/download/hand.png')} alt={'hand'} />
      </div>

    </div>
  </div>
);

export default AppHero;
