import AppHero from './AppHero';
import React, { Component } from 'react';

class AppHeroContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      platform: null
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextState) {

  }

  render() {
    return (
      <AppHero
        {...this.props}
      />
    )
  }
}

export default AppHeroContainer;
