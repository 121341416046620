import COLORS from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

const styles = StyleSheet.create({
  root: {
    background: COLORS.white
  },
  content: {
    textAlign: 'center',
    padding: '120px 32px',
    color: COLORS.offBlack,
    borderTop: '1px solid ' + COLORS.offWhite,
    maxWidth: 1200,
    margin: '0 auto'
  },
  buttonStyles: {
    backgroundColor: COLORS.kgGreen,
    color: COLORS.white,
    fontWeight: '900',
    height: '48px',
    marginTop: '8px',
    textAlign: 'left'
  },
  rightIcon: {
    float: 'right'
  },
  h1: {
    margin: 0,
    paddingBottom: 12
  },
  downloadHead: {
    color: COLORS.jjdGreyish,
    fontWeight: 600
  },
  downloadPara: {
    color: COLORS.jjdGreyish,
  },
  p: {
    maxWidth: 580,
    margin: '0 auto',
    paddingBottom: 32
  }
});

export default styles;
