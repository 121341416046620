import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';
import { FONTS } from '../../../styles/fonts';
import zIndex from '@material-ui/core/styles/zIndex';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    background: COLORS.white,
    padding: 16,
    paddingBottom: 64,
    paddingTop: 64,
    [QUERIES.mobile]: {
      paddingBottom: 64,
      paddingTop: 0,
    }
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto'
  },
  stepContainer: {
    paddingBottom: 46,
    display: 'flex',
    flexDirection: 'row',
    // flexWrap: 'wrap',
    // alignItems: 'center',
    // justifyContent: 'center',
    [QUERIES.desktop]: {
      paddingTop: 0,
      backgroundImage: 'linear-gradient(rgba(5, 5, 5, 0.3) 60%, rgba(255,255,255,0) 70%)',
      backgroundPosition: 'left',
      backgroundSize: '1px 20px',
      backgroundRepeat: 'repeat-y',
      ":last-child": {
        background: 'none',
        paddingBottom: 0,
      }
    },
    [QUERIES.lessThan800]: {
      paddingTop: 2,
      backgroundImage: 'linear-gradient(to right,rgba(5, 5, 5, 0.3) 60%, rgba(255, 255, 255, 0) 70%)',
      backgroundPosition: 'top',
      backgroundSize: '30px 2px',
      backgroundRepeat: 'repeat-x',
      marginTop: 50,
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  stepTitleArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -12
  },
  stepNumber: {
    width: 40,
    height: 40,
    background: COLORS.jjdRed,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '700',
    color: COLORS.white,
    fontSize: 20,
    marginRight: 40,
    zIndex: 2,
    [QUERIES.desktop]: {
      marginLeft: '-19px',
    },
    [QUERIES.lessThan800]: {
      marginTop: '-20px',
    },
    [QUERIES.lessThan600]: {
      marginRight: 0,
    }
  },
  finalStep: {
    background: COLORS.kgGreen
  },
  stepTitle: {
    fontSize: 24,
    color: COLORS.jjdGreyish,
    fontWeight: 600,
    fontFamily: FONTS.Content
  },
  stepDesc: {
    fontSize: 20,
    textAlign: 'justify',
    maxWidth: 424,
    color: COLORS.jjdGreyish,
    margin: '15px 0 0 0',
    [QUERIES.mobile]: {
      textAlign: 'center',
    }
  },
  stepInfo: {
    [QUERIES.mobile]: {
      margin: '7px auto',
      textAlign: 'center',
    }
  },
  MobileImgContainer: {
    display: 'flex',
    alignItems: 'center',
    [QUERIES.mobile]: {
      margin: '0 auto',
    }
  },
  mobileImage: {
    maxHeight: 744,
    maxWidth: '100%',
    minWidth: 300
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    [QUERIES.mobile]: {
      flexWrap: 'wrap'
    }
  },
  numberingList: {
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [QUERIES.desktop]: {
      margin: '0 0 0 52px',
    },
  }
});

export default styles;
