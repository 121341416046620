import React from 'react';
import { androidBase64, iosBase64 } from '../../../assets/download/base-64';
import styles from './Credibility.styles';
import { css } from 'aphrodite';
import STYLES from '../../../styles/shared';
import { LINKS } from '../../../library/links';
import Utils from '../../../library/utils';

const Credibility = () => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <a className={css(styles.a)} href={LINKS.MediumTechBlog} target={'blank'}>
        <img alt={'Stripe'} className={css(styles.img, STYLES.noSelect)} draggable={'false'} src={require('../../../assets/trust/stripe-blue.png')} />
      </a>
      <a href={LINKS.Trustpilot} target={'blank'}>
        <img className={css(styles.img, STYLES.noSelect)} draggable={'false'}
          src={require('../../../assets/trust/trustpilot-retina.png')}
          alt={'Trust Pilot Man and van'}
        />
      </a>
    </div>
  </div>
);

export default Credibility;
