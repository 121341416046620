import React from 'react';
import styles from './HowItWorks.styles';
import { css } from 'aphrodite';
import Tick from '@material-ui/icons/Check';
import SectionTitle from '../../../components/ui/SectionTitle/SectionTitle';

const HowItWorks = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
        <SectionTitle title={ 'How it works'} subTitle={ 'We load and transport anything, anytime'} />

         <div className={css(styles.row)}>
            <div className={css(styles.MobileImgContainer)}>
              <img alt={ 'Mobile Responsive'} className={css(styles.mobileImage)} draggable={false} src={require( '../../../assets/images/mobile.png')}/>
            </div>

            <div className={css(styles.numberingList)}>
              <div className={css(styles.stepContainer)}>
                <div className={css(styles.stepNumber)}>
                  1
                </div>
                <div className={css(styles.stepInfo)}>
                  <h3 className={css(styles.stepTitle)}>Enter your journey</h3>
                  <p className={css(styles.stepDesc)}>
                    We need a pickup address, destination point, and any additional stops along the way
                  </p>
                </div> 
              </div>

              <div className={css(styles.stepContainer)}>
                <div className={css(styles.stepNumber)}>
                  2
                </div>
                <div className={css(styles.stepInfo)}>
                  <h3 className={css(styles.stepTitle)}>Choose a van service</h3>
                  <p className={css(styles.stepDesc)}>
                    We'll help you select the right van size, and show you a final price instantly
                  </p>
                </div> 
              </div>

              <div className={css(styles.stepContainer)}>
                <div className={css(styles.stepNumber)}>
                  3
                </div>
                <div className={css(styles.stepInfo)}>
                  <h3 className={css(styles.stepTitle)}>Customise service</h3>
                  <p className={css(styles.stepDesc)}>
                    Choose up to 3 helpers for loading your stuff, ride along, or add extra time
                  </p>
                </div> 
              </div>

              <div className={css(styles.stepContainer)}>
                <div className={css(styles.stepNumber)}>
                  4
                </div>
                <div className={css(styles.stepInfo)}>
                  <h3 className={css(styles.stepTitle)}>Set arrival time & pay</h3>
                  <p className={css(styles.stepDesc)}>
                    Pay a small deposit upfront, and the rest in cash to the driver
                  </p>
                </div> 
              </div>
            </div>
          
          </div>

    </div>
</div>
);

export default HowItWorks;
