import React from 'react';
import Layout from '../containers/gatsby/layout';
import SEO from '../containers/gatsby/seo';
import CredibilitySection from '../containers/sections/Credibility/Credibility';
import AppInterface from '../containers/sections/AppInterface/AppInterface';
import DownloadFree from '../containers/sections/DownloadFree/DownloadFree';
import AppHeroContainer from '../containers/heroes/AppHero/AppHeroContainer';
import FeatureListApp from '../containers/sections/FeatureListApp/FeatureListApp';
import HowItWorksContainer from '../containers/sections/HowItWorks/HowItWorksContainer';

const HomePage = (props) => (
  <Layout location={props.location}>
    <SEO title="JJD - Man & Van App on the App Store and Google Play"
         keywords={[`JJD`, `man and van`, `man and van app`, `man and van app london`, `iPhone app man and van`, `uber for man and van`, `does uber do house removals`]}/>
    <AppHeroContainer/>
    <CredibilitySection/>
    <FeatureListApp/>
    <AppInterface/>
    <HowItWorksContainer/>
    <DownloadFree/>
  </Layout>
);

export default HomePage

