import React from 'react';
import AffordableIcon from '@material-ui/icons/SwapCalls';
import EasyIcon from '@material-ui/icons/Weekend';
import TrustedIcon from '@material-ui/icons/LocationCity';
import styles from './FeatureListApp.styles';
import {css} from 'aphrodite';
import STYLES from '../../../styles/shared';
import COLORS from '../../../styles/colors';

const FeatureListApp = () => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>

      <div className={css(STYLES.column)} style={{paddingTop: 64, paddingBottom: 64}}>
        <div className={css(styles.mainFeature)}>
          <div className={css(styles.mainFeatureImgContainer)}>
            <img alt={'Courier delivery app'} className={css(styles.mainFeatureImg, STYLES.cardBoxShadow)} src={require('../../../assets/phone/woman.png')}/>
          </div>
          <div className={css(styles.mainFeatureDescContainer)}>
            <h1 style={{color: COLORS.jjdGreyish, fontWeight: 600}}>The smarter way to book a man and van</h1>
            <p style={{paddingBottom: 32, color: COLORS.jjdGreyish}}>JJD is an on-demand Man and van app for Moving Furniture, House Removals, Moving Flat or Apartment, Same-Day Delivery, Office Moves and more. Find us on the App Store or Google Play!</p>
          </div>
        </div>
      </div>

      <div className={css(styles.moreFeatures)}>
        <div className={css(styles.featureContainer)}>
          <AffordableIcon className={css(styles.icon)}/>
          <h2 className={css(styles.h2)}>Affordable</h2>
          <p className={css(styles.p)}>We cost about the same as an UberXL - over 50% cheaper than traditional movers</p>
        </div>
        <div className={css(styles.featureContainer)}>
          <EasyIcon className={css(styles.icon)}/>
          <h2 className={css(styles.h2)}>Easy-to-use</h2>
          <p className={css(styles.p)}>We're turning the industry on its head as a tech leader for removal services</p>
        </div>
        <div className={css(styles.featureContainer)}>
          <TrustedIcon className={css(styles.icon)}/>
          <h2 className={css(styles.h2)}>Ready-to-go</h2>
          <p className={css(styles.p)}>At your door within 1 hour, ready to help load and transport</p>
        </div>
      </div>
    </div>
  </div>
);

export default FeatureListApp;
