import React from 'react';
import styles from './AppInterface.styles';
import { css } from 'aphrodite';
import STYLES from '../../../styles/shared';

const AppInterface = () => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>

      <div style={{flex: 1}} className={css(styles.imgContainer)}>
        <img alt={'App design'} className={css(styles.img, STYLES.noSelect)} draggable={'false'}
             src={require('../../../assets/download/interface.png')}/>
      </div>

      <div style={{flex: 1}} className={css(styles.getNotifiedDesc)}>
        <div>
          <h1 className={css(styles.h1)}>Simple, beautiful <br/> user interface</h1>
          <p className={css(styles.p)}>Our app is simple to use and prices are calculated instantly</p>
        </div>
      </div>
    </div>
  </div>
);

export default AppInterface;
