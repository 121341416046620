import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    background: COLORS.white,
    [QUERIES.desktop]: {
      paddingTop: 32,
      paddingBottom: 32
    }
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap-reverse',
    flexDirection: 'row',
    margin: '0 auto',
    padding: '36px 0',
    alignItems: 'center',
    justifyContent: 'center'
  },
  a: {
    flex: '0 0 1'
  },
  img: {
    height: 100,
    padding: '16px 24px',
    '-webkit-filter': 'grayscale(100%)',
    filter: 'grayscale(100%)',
    opacity: 0.64,
    transition: '150ms',
    ':hover': {
      '-webkit-filter': 'grayscale(0%)',
      filter: 'grayscale(0%)',
      opacity: 1,
    }
  },
  downloadImg: {
    height: 88,
    opacity: 0.48
  },
  trustCheckboxes: {
    listStyle: 'none',
    flex: '0 0 1',
    padding: 16,
    margin: 0
  },
  li: {
    color: '#919191',
    fontWeight: 900,
    fontSize: 16,
    height: 28
  }
});

export default styles;
