import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  title: {
    color: COLORS.jjdGreyish,
    fontSize: 66,
    fontWeight: 600,
    textAlign: 'center',
    paddingBottom: 6,
    margin: '150 0',
    position: 'relative',
    [QUERIES.mobile]: {
      fontSize: 34,
    }
  },
  subTitle: {
    textAlign: 'center',
    fontSize: 22,
    color: COLORS.jjdGreyish,
    marginTop: 0,
    [QUERIES.mobile]: {
      fontSize: 16,
    }
  },
  titleBottomBorder: {
    height: 4,
    width: 100,
    bottom: 200,
    left: 100,
    background: COLORS.jjdRed,
    margin: '0 auto',
    marginBottom: 64,
    borderRadius: 2
  },
});

export default styles;
