import COLORS from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    background: COLORS.jjdRed,
    padding: 32,
    paddingBottom: 0,
    [QUERIES.desktop]: {
      paddingTop: 64
    }
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  titleArea: {
    flex: '0 0 50%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'left',
    paddingTop: 88,
    [QUERIES.lessThan1200]: {
      flex: '0 0 100%',
      paddingTop: 0
    }
  },
  h1: {
    color: COLORS.white,
    margin: 0,
    textAlign: 'center',
    fontSize: 72,
    lineHeight: '72px',
    [QUERIES.lessThan1200]: {
      fontSize: 48,
      lineHeight: '48px'
    }
  },
  description: {
    color: COLORS.jjdGreyish,
    opacity: 0.4,
    fontWeight: '700',
    textAlign: 'center'
  },
  appLinksContainer: {
    marginTop: 32,
    textAlign: 'center',
    [QUERIES.lessThan1200]: {
      paddingBottom: 64
    }
  },
  downloadImg: {
    height: 64,
    transition: '150ms',
    marginRight: 16,
    borderRadius: 12,
    marginTop: 16,
    boxShadow: '0 6px 12px rgba(0,0,0,0.16)',
    ':hover': {
      transform: 'translateY(-3px)'
    }
  },
  imageArea: {
    flex: '0 0 50%',
    textAlign: 'right',
    [QUERIES.lessThan1200]: {
      flex: '0 0 100%',
      textAlign: 'center',
    }
  },
  appHandImage: {
    height: 624,
    paddingRight: 32
  },
});

export default styles;
