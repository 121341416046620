import COLORS from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    background: COLORS.white,
    color: COLORS.offBlack
  },
  content: {
    padding: 32,
    maxWidth: 1200,
    margin: '0 auto',
    [QUERIES.desktop]: {
      paddingBottom: 48
    }
  },
  icon: {
    color: COLORS.jjdRed,
    fontSize: '48px'
  },
  mainFeature: {
    // paddingTop: 48,
    // paddingBottom: 48,
    flexWrap: 'wrap-reverse',
    display: 'flex',
    flexDirection: 'row',
    [QUERIES.mobile]: {

    }
  },
  mainFeatureImgContainer: {
    flex: 1,
    textAlign: 'center'
  },
  mainFeatureImg: {
    width: '100%',
    maxWidth: 480,
    borderRadius: 8,
    minWidth: 300
  },
  mainFeatureDescContainer: {
    [QUERIES.desktop]: {
      flex: 1,
      padding: '0 32px',
    }
  },
  moreFeatures: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '32px 0',
    [QUERIES.desktop]: {
      paddingBottom: 64
    }
  },
  featureContainer: {
    flex: '100%',
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 24,
    [QUERIES.desktop]: {
      flex: 1,
      paddingRight: 32,
      paddingLeft: 32,
    }
  },
  h2: {
    margin: '4px 0 12px 0',
    fontSize: 24,
    fontWeight: 600,
    color: COLORS.jjdGreyish
  },
  p: {
    marginTop: 0,
    fontSize: 20,
    color: COLORS.jjdGreyish,
  }
});

export default styles;
