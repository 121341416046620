import React from 'react';
import styles from './SectionTitle.styles';
import { css } from 'aphrodite';

const SectionTitle = (props) => (
  <>
    <h2 className={css(styles.title)}>{props.title}</h2>
    <p className={css(styles.subTitle)}>{props.subTitle}</p>
    <div className={css(styles.titleBottomBorder)} />
  </>
);

export default SectionTitle;
