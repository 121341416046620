import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    background: COLORS.white
  },
  content: {
    maxWidth: 1200,
    margin: '64px auto 128px auto',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '0 24px',
    [QUERIES.mobile]: {
      margin: '32px auto',
    }
  },
  img: {
    width: '100%',
    [QUERIES.desktop]: {
      width: 444
    }
  },
  imgContainer: {
    flex: '0 0 50%',
    textAlign: 'center',
    [QUERIES.mobile]: {
      flex: '0 0 100%'
    }
  },
  getNotifiedDesc: {
    flex: '0 0 50%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    [QUERIES.mobile]: {
      flex: '0 0 100%',
      marginTop: 64,
      marginBottom: 112,
      textAlign: 'center'
    }
  },
  h1: {
    margin: 0,
    maxWidth: 480,
    color: COLORS.jjdGreyish,
    fontWeight: 600,
    [QUERIES.desktop]: {
      paddingTop: 168
    }
  },
  p: {
    color: COLORS.jjdGreyish,
    [QUERIES.desktop]: {
      maxWidth: 400
    }
  }
});

export default styles;
