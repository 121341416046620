import React, { Component } from 'react';
import styles from './DownloadFree.styles';
import Utils from '../../../library/utils';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { css } from 'aphrodite';

class DownloadFree extends Component {
  handleOnClick = () => {
    ReactGA.event({
      category: 'Browsing',
      action: 'Clicked download button - DownloadFree',
      label: this.props.history.location.pathname
    });
    ReactPixel.track('Lead', {
      content_name: 'Van Grid',
      content_category: 'Download Clicked'
    });

    window.open(Utils.getDownloadLink(), '_self');
  };

  render() {
    return (
      null
    )
  }
}

export default DownloadFree;
